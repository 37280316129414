<template>
  <div style="width: 550px">
    <h5 class="mb-7 px-10">{{ $t("HELP.TICKET.TITLE") }}</h5>

    <ZendeskForm
      class="px-0"
      ref="help-form"
      :isAuthenticated="currentUser.id != undefined"
    >
    </ZendeskForm>

    <div class="d-flex justify-content-end w-100 px-10">
      <button
        v-if="computedTypeOfIssueDisplay"
        @click="onSubmit"
        class="btn btn-primary btn-sm mx-1"
        :disabled="form.submissionStates.submitting"
      >
        {{ $t("GENERAL.BUTTONS.SUBMIT") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import AlertHelper from "@/modules/together-helpers/helpers/AlertHelper";

import ZendeskForm from "@/modules/together-helpers/help/Ticket";
import { CREATE_HELP_TICKET } from "@/modules/together-helpers/vuex/system/zendesk.module";

export default {
  name: "Help2",

  components: {
    ZendeskForm,
  },

  methods: {
    onSubmit: function () {
      if (!this.$refs["help-form"].canProgress()) {
        return false;
      }

      this.form.submissionStates.submitting = true;

      this.form.email = this.isAuthenticated
        ? this.currentUser.calculatedEmail
        : this.form.email;
      this.form.language = this.isAuthenticated
        ? this.currentUser.language.name
        : "English";

      let payload = {
        data: this.form,
      };

      payload.data.node = process.env.VUE_APP_SUBMODULE_IDENTIFIER;
      payload.data.user_id = this.isAuthenticated ? this.currentUser.id : null;
      payload.data.project_id = this.isProjectDomain
        ? this.projectInfo.id
        : null;

      payload.data.prefix = window.location.host.split(".")[0].includes();

      this.$store
        .dispatch(CREATE_HELP_TICKET, payload)
        .then(() => {
          AlertHelper.alertSuccess(this, "HELP_SENT_SUCCESS");
          this.$router.push({ name: "login" });
        })
        .finally(() => {
          this.form.submissionStates.submitting = false;
        });
    },
  },

  computed: {
    ...mapGetters(["currentUser"]),
    ...mapState({
      form: (state) => state.zendesk.help_form,
    }),
    computedTypeOfIssueDisplay: function () {
      let chosenOption = this.form.type_of_issue;
      let displayArray = [4, 5, 6];
      if (displayArray.includes(chosenOption)) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>